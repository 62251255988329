/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, ReactElement, useRef } from "react";
import axios from "axios";
import Card from "components/card";
import { Button, Badge } from '@chakra-ui/react';
import { FaAngleDown, FaAngleLeft } from "react-icons/fa";
import SweetAlert2 from "react-sweetalert2";
import BatchPayoutList from "./components/BatchPayoutList";
import BatchDetailErrorInfoModal from "./components/BatchDetailErrorInfoModal";
import { MdOutlineCancel, MdOutlineCheckCircleOutline, MdOutlinePlayCircleOutline } from "react-icons/md";
import { ImCogs } from "react-icons/im";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import BatchCancelConfirmModal from "./components/BatchCancelConfirmModal";
import BatchUpdateStatusConfirmModal from "./components/BatchUpdateStatusConfirmModal";
import BatchAssignProviderModal from "./components/BatchAssignProviderModal";
import BatchProcessConfirmModal from "./components/BatchProcessConfirmModal";
import { AiOutlineDownload } from "react-icons/ai";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const tz = process.env.REACT_APP_TIMEZONE || "Australia/Brisbane";

const rowPerPage = 10;

interface PayloadType {
  [key: string]: any;
}

const BatchDetail = () => {
  const [batchDetailForEdit, setBatchDetailForEdit] = useState(null);
  const [batchProviderList, setBatchProviderList] = useState(null);
  const [swalProps, setSwalProps] = useState<any>({});
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFetchProvider, setIsFetchProvider] = useState<boolean>(true);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [lastKeys, setLastKeys] = useState<{ [key: number]: string }>({});
  const [currentLastKey, setCurrentLastKey] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(rowPerPage);
  const [rowCount, setRowCount] = useState(rowPerPage);
  const [isOpenBatchDetailErrorInfoModal, setIsOpenBatchDetailErrorInfoModal] = useState<boolean>(false);

  const [batchToCancel, setBatchToCancel] = useState<any>(null);
  const [isOpenBatchCancelConfirmModal, setIsOpenBatchCancelConfirmModal] = useState<boolean>(false);

  const [batchToUpdateStatus, setBatchToUpdateStatus] = useState<any>(null);
  const [isOpenBatchUpdateStatusConfirmModal, setIsOpenBatchUpdateStatusConfirmModal] = useState<boolean>(false);

  const [batchToAssignProvider, setBatchToAssignProvider] = useState<any>(null);
  const [isOpenBatchAssignProviderModal, setIsOpenBatchAssignProviderModal] = useState<boolean>(false);

  const [batchToProcessConfirm, setBatchToProcessConfirm] = useState<any>(null);
  const [isOpenBatchProcessConfirmModal, setIsOpenBatchProcessConfirmModal] = useState<boolean>(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [statusToUpdate, setStatusToUpdate] = useState<string>("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole")?.toLowerCase();

  useEffect(() => {
    const fetchData = async () => {
      await fetchBatchDetail(id);
    };

    if (batchDetailForEdit === null) {
      fetchData();
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); // eslint-disable-line

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  const fetchBatchDetail = async (batchId: string) => {
    try {
      setBatchDetailForEdit(null);
      setIsLoading(true);

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/batch/${batchId}`
      );

      let batchData = response.data.batch?.payload;

      setBatchDetailForEdit(batchData);
      await fetchPayouts({ page: 0, limit: rowPerPage, lastkey: "", batch_id: id });
      await fetchBatchProvider(String(id));
    } catch (error) {
      setTableData([]);
      setIsLoading(false);
      console.error("Error fetching data", error);
    }
  };

  const fetchBatchProvider = async (id: string) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/batch/method/batch/${id}`
      );

      let providerData = response.data.provider;

      setBatchProviderList(providerData);
      setIsFetchProvider(false);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchPayouts = async (payload: PayloadType = {}) => {
    setIsLoading(true);
    try {
      const queryObject: PayloadType = {
        limit: payload?.limit,
        lastkey: payload?.lastkey,
        batch_id: payload?.batch_id,
        usertype: userRole === 'superadmin' ? "a" : "m"
      };

      const queryString = Object.keys(queryObject)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
        )
        .join("&");

      const response = await axios.get(
        process.env.REACT_APP_API_URL +
        `/api/payout/get-all-payouts?${queryString}`
      );

      let newData = response.data.payouts?.payload;

      if (newData.length !== 0) {
        setTableData(newData);
      } else {
        setTableData([]);
      }
      setIsLoading(false);

      let newLastKey = response.data.payouts?.lastkey;
      if (newLastKey) {
        setCurrentLastKey(newLastKey);
        let newPage = payload?.page;
        let newPageSize = payload?.limit;

        if (newData.length !== 0) {
          setRowCount((newPage + 1) * newPageSize + newPageSize);
        } else {
          setRowCount((newPage) * newPageSize + newPageSize);
        }
        setLastKeys({ ...lastKeys, [newPage]: newLastKey });
      } else {
        setCurrentLastKey("");
      }
    } catch (error) {
      setTableData([]);
      setIsLoading(false);
      setPage(0);
      setPageSize(0);
      setRowCount(0);
      setLastKeys([]);
      setCurrentLastKey("");
      console.error("Error fetching data", error);
    }
  };

  const setPaginationModel = (newValue: any) => {
    setIsLoading(true);

    let newFilters: any = {
      page: newValue.page,
      limit: newValue.pageSize,
      lastkey: currentLastKey,
      batch_id: id
    };

    if (newValue.page < page) {
      newFilters = {
        ...newFilters,
        lastkey: newValue.page !== 0 ? lastKeys[newValue.page - 1] : "",
      };
    }

    if (newValue.pageSize !== pageSize) {
      newFilters = {
        page: 0,
        limit: newValue.pageSize,
        lastkey: "",
        batch_id: id
      };
      setPage(0);
      setPageSize(newValue.pageSize);
      setCurrentLastKey("");
      setLastKeys([]);
    } else {
      setPage(newValue.page);
    }

    fetchPayouts(newFilters);
  };

  interface BatchStatusItems {
    status: string;
    value: string;
    display: ReactElement;
  }

  const statusClasses = 'status';

  const statusList: BatchStatusItems[] = [
    { status: "open", value: 'open', display: <Badge className={`${statusClasses} status-open`}>Open</Badge> },
    { status: "close", value: 'close', display: <Badge className={`${statusClasses} status-closed`}>Closed</Badge> },
    { status: "queue", value: 'queue', display: <Badge className={`${statusClasses} status-queued`}>Queued</Badge> },
    { status: "pending", value: 'pending', display: <Badge className={`${statusClasses} status-pending`}>Pending</Badge> },
    { status: "in_progress", value: 'in_progress', display: <Badge className={`${statusClasses} status-in_progress`}>In Progress</Badge> },
    { status: "settled", value: 'settled', display: <Badge className={`${statusClasses} status-settled`}>Settled</Badge> },
    { status: "settled_with_error", value: 'settled_with_error', display: <Badge className={`${statusClasses} status-settled_with_error`}>Settled with Errors</Badge> },
    { status: "cancelled", value: 'cancelled', display: <Badge className={`${statusClasses} status-cancelled`}>Cancelled</Badge> },
    { status: "failed", value: 'failed', display: <Badge className={`${statusClasses} status-failed`}>Failed</Badge> },
  ];

  const statusDisplay = (status: string) => {
    const statusItem = statusList.find(item => item.status === status);
    return statusItem ? statusItem.display : null;
  };

  const formatDate = (timestamp: string, time: boolean = false) => {
    if (timestamp && timestamp !== "") {
      if (time) {
        return dayjs(Number(timestamp)).tz(tz).format("DD MMM YY - hh:mm A");
      } else {
        return dayjs(Number(timestamp)).tz(tz).format("DD MMM YY");
      }
    }
    return "-";
  };

  const showErrorInfoModal = () => {
    setIsOpenBatchDetailErrorInfoModal(true);
  }

  const closeErrorInfoModal = () => {
    setIsOpenBatchDetailErrorInfoModal(false);
  }

  const handleClickBack = () => {
    navigate(`/${userRole}/batch`);
  }

  const handleClickCancel = () => {
    setBatchToCancel(batchDetailForEdit);
    showBatchCancelConfirmModal();
  };

  const showBatchCancelConfirmModal = () => {
    setIsOpenBatchCancelConfirmModal(true);
  }

  const closeBatchCancelConfirmModal = () => {
    setBatchToCancel(null);
    setIsOpenBatchCancelConfirmModal(false);
  }

  const handleClickUpdateStatus = (status: string) => {
    setIsDropdownOpen(false);
    setStatusToUpdate(status);
    setBatchToUpdateStatus(batchDetailForEdit);
    showBatchUpdateStatusConfirmModal();
  };

  const showBatchUpdateStatusConfirmModal = () => {
    setIsOpenBatchUpdateStatusConfirmModal(true);
  }

  const closeBatchUpdateStatusConfirmModal = () => {
    setBatchToUpdateStatus(null);
    setIsOpenBatchUpdateStatusConfirmModal(false);
  }

  const handleClickAssignProvider = () => {
    setBatchToAssignProvider(batchDetailForEdit);
    showBatchAssignProviderModal();
  };

  const showBatchAssignProviderModal = () => {
    setIsOpenBatchAssignProviderModal(true);
  }

  const closeBatchAssignProviderModal = () => {
    setBatchToAssignProvider(null);
    setIsOpenBatchAssignProviderModal(false);
  }

  const handleClickProcessConfirm = () => {
    setBatchToProcessConfirm(batchDetailForEdit);
    showBatchProcessConfirmModal();
  };

  const showBatchProcessConfirmModal = () => {
    setIsOpenBatchProcessConfirmModal(true);
  }

  const closeBatchProcessConfirmModal = () => {
    setBatchToProcessConfirm(null);
    setIsOpenBatchProcessConfirmModal(false);
  }

  const triggerReloadBatchDetail = async () => {
    setIsLoading(true);
    await fetchBatchDetail(id);
  }

  const triggerReloadPayoutList = async () => {
    setIsLoading(true);
    let pendingPayoutLeftAmount = tableData.filter((x: any) => x.status === 'pending');
    if (pendingPayoutLeftAmount.length === 1) {
      await fetchBatchDetail(id);
    } else {
      await fetchPayouts({ page: 0, limit: rowPerPage, lastkey: "", batch_id: batchDetailForEdit.id });
    }
  }

  const handleClickDownload = async (file_bucket: string, file_key: string, file_name: string) => {
    try {
      setIsDownloading(true);

      const postData = {
        file_bucket: file_bucket,
        file_key: file_key
      }

      await axios.post(
        process.env.REACT_APP_API_URL + `/api/batch/generate-download-url`,
        postData
      )
        .then(async function (response) {
          setIsDownloading(false);
          if (response && response.data?.status === 'ok') {
            const fileUrl = response.data.url;
            const fileResponse = await fetch(fileUrl);
            const blob = await fileResponse.blob();

            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = file_name;
            link.click();

            window.URL.revokeObjectURL(link.href);
          } else {
            setSwalProps({
              show: true,
              icon: "error",
              title: "Oops!",
              html: response.data.message,
              showConfirmButton: true,
              didClose: () => {
                setSwalProps({});
              },
            });
          }
        })
        .catch(function (error) {
          setSwalProps({
            show: true,
            icon: "error",
            title: "Oops!",
            html: error.response.data.message,
            showConfirmButton: true,
            didClose: () => {
              setSwalProps({});
            },
          });
        });
    } catch (error) {
      setSwalProps({
        show: true,
        icon: "error",
        title: "Oops!",
        html: "Something went wrong, please try again later.",
        showConfirmButton: true,
        didClose: () => {
          setSwalProps({});
        },
      });
    }
  };

  const displayAmount = (amount: any) => {
    if (amount === 0) {
      return "$0.00";
    }
    return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <>

      <BatchCancelConfirmModal setSwalProps={setSwalProps} triggerReload={triggerReloadBatchDetail} isOpen={isOpenBatchCancelConfirmModal} batchToCancel={batchToCancel} onClose={() => closeBatchCancelConfirmModal()} />

      {userRole === 'superadmin' &&
        <>
          <BatchAssignProviderModal setSwalProps={setSwalProps} triggerReload={triggerReloadBatchDetail} isOpen={isOpenBatchAssignProviderModal} batchToAssignProvider={batchToAssignProvider} batchProviderList={batchProviderList} onClose={() => closeBatchAssignProviderModal()} />
          <BatchProcessConfirmModal setSwalProps={setSwalProps} triggerReload={triggerReloadBatchDetail} isOpen={isOpenBatchProcessConfirmModal} batchToProcessConfirm={batchToProcessConfirm} onClose={() => closeBatchProcessConfirmModal()} />
          <BatchUpdateStatusConfirmModal setSwalProps={setSwalProps} statusToUpdate={statusToUpdate} triggerReload={triggerReloadBatchDetail} isOpen={isOpenBatchUpdateStatusConfirmModal} batchToUpdateStatus={batchToUpdateStatus} onClose={() => closeBatchUpdateStatusConfirmModal()} />
        </>
      }

      {
        batchDetailForEdit !== null && batchDetailForEdit.validation_results && <BatchDetailErrorInfoModal data={batchDetailForEdit.validation_results} isOpen={isOpenBatchDetailErrorInfoModal} onClose={() => closeErrorInfoModal()} />
      }

      <Card className="mt-6 card  card-pad" >
        {batchDetailForEdit !== null ? (
          <>
            <div className="flex items-center mb-3">
              <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                Batch {batchDetailForEdit !== null ? (<>{`(${batchDetailForEdit.id})`}</>) : (<>{"-"}</>)}
              </h4>
              <div className="ml-auto flex items-center">
                {batchDetailForEdit.status === 'open' && (userRole === 'admin' || userRole === 'merchant') &&
                  <>
                    <Button
                      isDisabled={batchDetailForEdit === null || isLoading}
                      onClick={() => handleClickCancel()}
                      className="rounded-xl border border-red-500 btn text-sm font-medium text-red-500 hover:text-white hover:bg-red-500 transition duration-200 active:bg-red-700 dark:border-red-400 dark:text-red-400 dark:active:bg-red-200"
                    >
                      <MdOutlineCancel />&nbsp;Cancel
                    </Button>
                    <span className="inline-block h-8 border-l border-gray-300 mx-2"></span>
                  </>
                }
                {batchDetailForEdit.status === 'in_progress' && batchDetailForEdit.is_manual === "false" && batchDetailForEdit.is_can_process_again === "true" && userRole === 'superadmin' &&
                  <>
                    <Button
                      isDisabled={batchDetailForEdit === null || isLoading}
                      onClick={() => handleClickProcessConfirm()}
                      className="rounded-xl border border-amber-500 btn text-sm font-medium text-amber-500 hover:text-white hover:bg-amber-500 transition duration-200 active:bg-amber-700 dark:border-amber-400 dark:text-amber-400 dark:active:bg-amber-200"
                    >
                      <MdOutlinePlayCircleOutline className="mr-2 text-base" />
                      Process
                    </Button>
                    <span className="inline-block h-8 border-l border-gray-300 mx-2"></span>
                  </>
                }
                {((
                  ['open', 'close', 'queue'].includes(batchDetailForEdit.status) || (batchDetailForEdit.status === 'in_progress'
                    && batchDetailForEdit.is_manual === "true"))
                  && userRole === 'superadmin')
                  &&
                  <>
                    <div ref={dropdownRef}>
                      <Button
                        onClick={toggleDropdown}
                        isDisabled={batchDetailForEdit === null || isLoading || isDownloading || isFetchProvider}
                        className="btn btn-default"
                      >
                        {
                          (batchDetailForEdit === null || isLoading || isFetchProvider) &&
                          <>Preparing...</>
                        }
                        {
                          batchDetailForEdit && !isLoading && !isFetchProvider && 
                          <>
                            Action&nbsp;<FaAngleDown />
                          </>
                        }
                      </Button>
                      {isDropdownOpen && (
                        <div className="origin-top-right absolute mt-2 rounded-md shadow-lg bg-white border border-gray-300">
                          <div className="p-2" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                            {userRole === 'superadmin' && (batchDetailForEdit.status === 'open' || batchDetailForEdit.status === 'close') &&
                              <span
                                onClick={() => handleClickAssignProvider()}
                                className="flex items-center px-2 py-2 text-sm font-medium text-navy-500 hover:bg-navy-100 cursor-pointer"
                              >
                                <ImCogs className="mr-2 text-base" />
                                Assign Provider
                              </span>
                            }
                            {userRole === 'superadmin' && batchDetailForEdit.status === 'close' &&
                              <span
                                onClick={() => handleClickProcessConfirm()}
                                className="flex items-center px-2 py-2 text-sm font-medium text-amber-500 hover:bg-amber-100 cursor-pointer"
                              >
                                <MdOutlinePlayCircleOutline className="mr-2 text-base" />
                                Process
                              </span>
                            }
                            {userRole === 'superadmin' && batchDetailForEdit.status === 'queue' && batchDetailForEdit.file_bucket !== null && batchDetailForEdit.file_key !== null &&
                              <span
                                onClick={() => handleClickUpdateStatus("in_progress")}
                                className="flex items-center px-2 py-2 text-sm font-medium text-amber-500 hover:bg-amber-100 cursor-pointer"
                              >
                                <MdOutlinePlayCircleOutline className="mr-2 text-base" />
                                Mark Batch as In Progress
                              </span>
                            }
                            {userRole === 'superadmin' && (batchDetailForEdit.status === 'open' || batchDetailForEdit.status === 'close' || batchDetailForEdit.status === 'queue') &&
                              <span
                                onClick={() => handleClickCancel()}
                                className="flex items-center px-2 py-2 text-sm font-medium text-red-500 hover:bg-red-100 cursor-pointer"
                              >
                                <MdOutlineCancel className="mr-2 text-base" />
                                Cancel
                              </span>
                            }
                            {userRole === 'superadmin' && batchDetailForEdit.status === 'in_progress' && batchDetailForEdit.is_manual === "true" &&
                              <span
                                onClick={() => handleClickUpdateStatus("settled")}
                                className="flex items-center px-2 py-2 text-sm font-medium text-green-500 hover:bg-green-100 cursor-pointer"
                              >
                                <MdOutlineCheckCircleOutline className="mr-2 text-base" />
                                Mark Batch as Settled
                              </span>
                            }
                            {userRole === 'superadmin' && batchDetailForEdit.status === 'in_progress' && batchDetailForEdit.is_manual === "true" &&
                              <span
                                onClick={() => handleClickUpdateStatus("failed")}
                                className="flex items-center px-2 py-2 text-sm font-medium text-red-500 hover:bg-red-100 cursor-pointer"
                              >
                                <MdOutlineCancel className="mr-2 text-base" />
                                Mark Batch as Failed
                              </span>
                            }
                          </div>
                        </div>
                      )}
                    </div>
                    <span className="inline-block h-8 border-l border-gray-300 mx-2"></span>
                  </>
                }
                <Button
                  isDisabled={batchDetailForEdit === null || isLoading || isDownloading}
                  onClick={() => handleClickBack()}
                  className="transition duration-200 btn btn-default"
                >
                  <FaAngleLeft />&nbsp;Return to Batches
                </Button>
              </div>
            </div>
            <hr className="mb-8" />
            <div className="flex flex-wrap justify-between text-sm text-navy-700 dark:text-white">
              <div className="w-1/2 pr-4">
                <div className="mb-4">
                  <span className="mr-1 font-bold">Created Date:</span> {batchDetailForEdit !== null ? (formatDate(batchDetailForEdit.created_at)) : (<>{"-"}</>)}
                </div>
                {userRole === 'superadmin' && batchDetailForEdit.merchant && batchDetailForEdit.subaccount ?
                  <>
                    <div className="mb-4">
                      <span className="mr-1 font-bold">Merchant:</span> {batchDetailForEdit !== null ? batchDetailForEdit.merchant.name : (<>{"-"}</>)}
                    </div>
                    <div className="mb-4">
                      <span className="mr-1 font-bold">Subaccount:</span> {batchDetailForEdit !== null ? batchDetailForEdit.subaccount.name : (<>{"-"}</>)}
                    </div>
                  </>
                  : null}
                <div className="mb-4">
                  <span className="mr-1 font-bold">Reference:</span> {batchDetailForEdit !== null ? batchDetailForEdit.batch_reference : (<>{"-"}</>)}
                </div>
                {userRole === 'superadmin' && batchDetailForEdit.file_bucket !== null && batchDetailForEdit.file_key !== null &&
                  <>
                    <div className="mb-4">
                      <span className="mr-1 font-bold">File:</span>
                      {batchDetailForEdit.file_bucket !== null && batchDetailForEdit.file_key !== null ?
                        <>
                          {batchDetailForEdit.file_name}
                          <Button
                            isDisabled={isDownloading}
                            onClick={() => handleClickDownload(batchDetailForEdit.file_bucket, batchDetailForEdit.file_key, batchDetailForEdit.file_name)}
                            className={`rounded-lg bg-cyan-500 px-2 py-1 text-xs text-white transition duration-200 hover:bg-cyan-600 active:bg-cyan-700 dark:bg-cyan-400 dark:text-white dark:hover:bg-cyan-300 dark:active:bg-cyan-200 ${batchDetailForEdit.file_name && batchDetailForEdit.file_name !== "" ? "ml-1" : ""}`}
                          >
                            Download <AiOutlineDownload className="h-4 w-4 text-white ml-1" />
                          </Button>
                        </>
                        :
                        "-"
                      }
                    </div>
                  </>
                }
                <div className="mb-4">
                  <span className="mr-1 font-bold">Total Amount:</span> {batchDetailForEdit !== null ? batchDetailForEdit.amount ? displayAmount(batchDetailForEdit.amount) : '-' : (<>{"-"}</>)}
                </div>
                <div className="mb-4">
                  <span className="mr-1 font-bold">Asset:</span> {batchDetailForEdit !== null ? batchDetailForEdit.asset : (<>{"-"}</>)}
                </div>
              </div>
              <div className="w-1/2 pr-4">
                <div className="mb-4">
                  <span className="mr-1 font-bold">Process Date:</span> {batchDetailForEdit !== null ? (formatDate(batchDetailForEdit.process_date, true)) : (<>{"-"}</>)}
                </div>
                <div className="mb-4">
                  <span className="mr-1 font-bold">Total Record(s):</span> {batchDetailForEdit !== null ? batchDetailForEdit.record : (<>{"-"}</>)}
                </div>
                <div className="mb-4">
                  <span className="mr-1 font-bold">Processed Record(s):</span> {!batchDetailForEdit !== null ? batchDetailForEdit.total_tx && batchDetailForEdit.status !== 'queue' ? batchDetailForEdit.total_tx : '-' : (<>{"-"}</>)}
                </div>
                <div className="mb-4">
                  <span className="mr-1 font-bold">Status:</span>{batchDetailForEdit !== null ? (statusDisplay(batchDetailForEdit.status)) : (<>{"-"}</>)}
                </div>
                {userRole === 'superadmin' && batchDetailForEdit.provider_name && batchDetailForEdit.provider_account_name && batchDetailForEdit.provider_method_name &&
                  <>
                    <div className="mb-4">
                      <span className="mr-1 font-bold">Provider:</span> {batchDetailForEdit !== null && batchDetailForEdit.provider_name !== null ? batchDetailForEdit.provider_name + " (" + batchDetailForEdit.provider_account_name + ")" : (<>{"-"}</>)}
                    </div>
                    <div className="mb-4">
                      <span className="mr-1 font-bold">Provider Method:</span> {batchDetailForEdit !== null && batchDetailForEdit.provider_method_name !== null ? batchDetailForEdit.provider_method_name : (<>{"-"}</>)}
                    </div>
                  </>
                }
                {batchDetailForEdit.validation_results && batchDetailForEdit.validation_results.filter((x: any) => !x.isValid).length > 0 &&
                  <div className="mb-4">
                    <span className="mr-1 font-bold">Error Info:</span>
                    <Button
                      isDisabled={batchDetailForEdit === null}
                      onClick={() => showErrorInfoModal()}
                      className="transition duration-200 btn btn-primary"
                    >
                      View
                    </Button>
                  </div>
                }
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3 mt-3" style={{ animationDuration: '0.6s' }}></div>
            <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
            <div className="h-5 bg-gray-200 rounded-md animate-pulse mb-3" style={{ animationDuration: '0.6s' }}></div>
          </>
        )}
      </Card>

      {batchDetailForEdit && (batchDetailForEdit.status !== 'queue' || batchDetailForEdit.batch_type === 'API') &&
        <Card className="mt-6 card">
          <BatchPayoutList
            isLoading={isLoading}
            page={page}
            pageSize={pageSize}
            rowCount={rowCount}
            tableData={isLoading ? [] : tableData}
            setPaginationModel={setPaginationModel}
            currentLastKey={currentLastKey}
            triggerReloadPayoutList={triggerReloadPayoutList}
            batchType={batchDetailForEdit.batch_type}
            batchFileBucket={batchDetailForEdit.file_bucket}
            batchFileKey={batchDetailForEdit.file_key}
            batchStatus={batchDetailForEdit.status}
          />
        </Card>
      }

      <SweetAlert2 {...swalProps} customClass={{ actions: 'custom-swal2-popup-action', title: 'custom-swal2-popup-title', confirmButton: 'custom-swal2-popup-action custom-swal2-popup-action-confirm', cancelButton: 'custom-swal2-popup-action custom-swal2-popup-action-cancel' }}>
      </SweetAlert2>
    </>
  );
};

export default BatchDetail;
