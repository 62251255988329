import React from "react";
import { Box } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridClasses,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const tz = process.env.REACT_APP_TIMEZONE || "Australia/Brisbane";

type RowData = {
  id: string;
  action: string;
  created_at: string;
  details: string;
  logged_role: string;
  logged_username: string;
  subaccount_id: string;
  updated_at: string;
};

const ActivityLogList: React.FC<{
  isLoading: boolean;
  tableData: RowData[];
  page: number;
  pageSize: number;
  rowCount: number;
  setPaginationModel: any;
  currentLastKey: string;
}> = ({
  isLoading,
  tableData,
  page,
  pageSize,
  rowCount,
  setPaginationModel,
  currentLastKey,
}) => {
  const columns: GridColDef[] = [
    {
      field: "created_at",
      headerName: "Created Date",
      sortable: false,
      minWidth: 140,
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, string>) =>
        formatDate(params.row.created_at),
    },
    {
      field: "logged_role",
      headerName: "Role",
      sortable: false,
      minWidth: 150,
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.logged_role,
    },
    {
      field: "username",
      headerName: "Username",
      sortable: false,
      minWidth: 150,
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.logged_username ? params.row.logged_username : "",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      minWidth: 150,
      maxWidth: 150,
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.action,
    },
    {
      field: "details",
      headerName: "Details",
      sortable: false,
      minWidth: 800,
      flex: 1,
      renderCell: (params: GridRenderCellParams<RowData, any, string>) =>
        params.row.details,
    },
  ];

  const formatDate = (timestamp: string) => {
    return dayjs(Number(timestamp)).tz(tz).format("DD MMM YY - hh:mm A");
  };

  const customDataGridStyles: Partial<GridClasses> = {
    root: "mui-table-custom",
    columnHeader: "text-start text-md",
    row: "",
  };

  return (
    <div>
      <div className="mt-6 flex flex-wrap pb-4 pl-3">
        <h3 className="text-lg font-bold text-navy-700 dark:text-white">
          Activity Logs
        </h3>
      </div>
      <Box className="card w-full">
        <DataGrid
          sx={{
            "& .MuiDataGrid-cell": {
              padding: "8px 16px",
            },
            "& .MuiDataGrid-columnHeader": {
              padding: "8px 16px",
            },
            "& .MuiTablePagination-input": {
              marginRight: "-20px",
            },
            "& .MuiTablePagination-displayedRows": {
              display: "none",
            },
          }}
          loading={isLoading}
          rows={tableData || []}
          columns={columns}
          getRowId={(row) => row.id}
          rowCount={rowCount}
          sortingMode="server"
          paginationMode="server"
          pageSizeOptions={[10, 25, 50, 100]}
          rowHeight={48}
          columnHeaderHeight={48}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(newValue) => setPaginationModel(newValue)}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          classes={customDataGridStyles}
          hideFooter={currentLastKey === "" && page === 0}
          autoHeight
        />
      </Box>
    </div>
  );
};

export default ActivityLogList;
