/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import DynamicInputField from "components/fields/DynamicInputField";
import { Select, Button } from '@chakra-ui/react';

const CreateReceiverAccountModal: React.FC<{
    setSwalProps: any;
    receiverId: string;
    isOpen: boolean;
    onClose(): void;
    triggerReloadReceiverAccountList(): void;
}> = ({ setSwalProps, isOpen, onClose, receiverId, triggerReloadReceiverAccountList }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [methodInfoList, setMethodInfoList] = useState<any>([]);
    const [assetList, setAssetList] = useState<any>([]);
    const [asset, setAsset] = useState<string>("");
    const [method, setMethod] = useState<string>("");
    const [filteredMethodList, setFilteredMethodList] = useState<any>([]);
    const [filteredMethodFields, setFilteredMethodFields] = useState<any>([]);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<any>("");
    const createFormRef = useRef(null);
    const subaccountId = localStorage.getItem("subaccountId");

    const fetchAccountDetail = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(
                process.env.REACT_APP_API_URL +
                `/api/subaccount/get-subaccount-by-id/${subaccountId}`
            );
            const accountData = response.data.subaccounts?.payload;

            const buildUpAsset = accountData.account_methods.reduce((acc: string[], item: any) => {
                if (!acc.includes(item.asset)) {
                    acc.push(item.asset);
                }
                return acc;
            }, []);
            setAssetList(buildUpAsset);

            setMethodInfoList(accountData.account_methods);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching data", error);
        }
    };

    const closeModal = async () => {
        onClose();
        setErrorMessage("");
        setAsset("");
        setMethod("");
        setFilteredMethodList([]);
        setFilteredMethodFields([]);
    };

    const submitHandler = async (e: any) => {
        setErrorMessage("");

        e.preventDefault();

        const formData = new FormData(createFormRef.current);
        const formObj = Object.fromEntries(formData.entries());

        formObj.receiver_id = receiverId;
        formObj.asset = asset;
        formObj.method = method;

        try {
            setIsCreating(true);

            formObj.username = localStorage.getItem("username");
            formObj.userRole = localStorage.getItem("userRole")?.toLowerCase();
            formObj.subaccount_id = localStorage.getItem("subaccountId");

            await axios.post(
                process.env.REACT_APP_API_URL + `/api/receiveraccount/create`,
                formObj
            )
                .then(function (response) {
                    if (response && response.data?.status === 'ok') {
                        closeModal();
                        setSwalProps({
                            show: true,
                            icon: "success",
                            title: "Success",
                            html: "Add receiver account successfully",
                            showConfirmButton: true,
                            didClose: () => {
                                setSwalProps({});
                                setIsCreating(false);
                                triggerReloadReceiverAccountList();
                            },
                        });
                    } else {
                        setIsCreating(false);
                        setErrorMessage('Add receiver account has been failed');
                    }
                })
                .catch(function (error) {
                    setIsCreating(false);
                    if (error.response) {
                        setErrorMessage(error.response.data.message);
                    } else {
                        setErrorMessage('Add receiver account has been failed');
                    }
                });
        } catch (error: any) {
            setIsCreating(false);
            setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: "Something went wrong, please try again later.",
                showConfirmButton: true,
                didClose: () => {
                    setSwalProps({});
                    setIsCreating(false);
                },
            });
        }
    };

    const handleAssetDropdownChange = async (event: any) => {
        setIsLoading(true);
        setFilteredMethodList([]);
        setFilteredMethodFields([]);
        setMethod("");

        const { value } = event.target;
        setAsset(value);
        if (value !== "") {
            const getListResult = await methodInfoList.filter((x: any) => x.asset === value);
            setFilteredMethodList(getListResult);
            setIsLoading(false);
        }
    };

    const handleMethodDropdownChange = async (event: any) => {
        const { value } = event.target;
        if (value !== "") {
            setMethod(value);
            const getInfo = methodInfoList.find((x: any) => x.asset === asset && x.method_name === value);
            setFilteredMethodFields(getInfo.fields);
        }
    };

    const fieldsRendering = (fields: any) => {
        const renderInput = (field: any) => {
            const capitalizeLabel = (label: any) => {
                let modifiredLabel = label.split('_')
                    .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');

                switch (label) {
                    case "bsb":
                        modifiredLabel = "BSB";
                        break;
                }

                return modifiredLabel;
            };

            let fieldType = "text";
            switch (field.type) {
                case "number":
                    fieldType = "number";
                    break;
                case "string":
                default:
                    fieldType = "text";
                    break;
            }

            let dropdownOption: string[] = [];
            if (field.enum_values) {
                fieldType = "select"
                dropdownOption = field.enum_values.split("|");
            }

            return (
                <>
                    {fieldType !== "select" && (
                        <DynamicInputField
                            key={field.name}
                            minLength={field.length.min}
                            maxLength={field.length.max}
                            disabled={isCreating || isLoading}
                            variant="auth"
                            extra="mb-3 shadow-sm"
                            label={capitalizeLabel(field.name) + (field.required ? " *" : "")}
                            id={field.name}
                            name={field.name}
                            type={fieldType}
                            required={field.required}
                            autoComplete="off"
                        />
                    )}
                    {fieldType === "select" && (
                        <>
                            <label className="mb-1 text-sm text-navy-700 dark:text-white font-medium">
                                {capitalizeLabel(field.name)}{(field.required ? " *" : "")}
                            </label>
                            <Select
                                required={field.required}
                                disabled={isCreating || isLoading}
                                id={field.name}
                                name={field.name}
                                className="h-10 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full mb-3 shadow-sm"
                            >
                                <option value="">-- Please Select --</option>
                                {dropdownOption?.length > 0 &&
                                    dropdownOption.map((option: any) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                            </Select>
                        </>
                    )}
                </>
            );
        };

        return fields.map(renderInput);
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchAccountDetail();
        };

        if (isOpen && assetList.length === 0) {
            fetchData();
        }
    }, [isOpen]); // eslint-disable-line

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                    <ModalBody className="!text-navy-700">
                        <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004]">
                            <form onSubmit={submitHandler} ref={createFormRef}>
                                <h1 className="mb-[20px] text-2xl font-bold">Add New Account</h1>
                                <div className="mb-[20px]">
                                    <label className="mb-1 text-sm text-navy-700 dark:text-white font-medium">
                                        Asset *
                                    </label>

                                    <Select required={true} disabled={isCreating || isLoading} id="asset" value={asset} onChange={handleAssetDropdownChange} className="h-10 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full mb-3 shadow-sm">
                                        <option value="">{isLoading ? "Retrieving Available..." : "-- Please Select --"}</option>
                                        {assetList.map((assetItem: any) => (
                                            <option key={String(assetItem).toLowerCase()} value={assetItem}>{assetItem}</option>
                                        ))}
                                    </Select>

                                    <label className="mb-1 text-sm text-navy-700 dark:text-white font-medium">
                                        Method *
                                    </label>

                                    <Select required={true} disabled={isCreating || isLoading} id="method" value={method} onChange={handleMethodDropdownChange} className="h-10 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full mb-3 shadow-sm">
                                        <option value="">-- Please Select --</option>
                                        {filteredMethodList && filteredMethodList.length > 0 && filteredMethodList.map((type: any) => (
                                            <option key={type.method_name} value={type.method_name}>{type.method_name}</option>
                                        ))}
                                    </Select>

                                    {filteredMethodFields && fieldsRendering(filteredMethodFields)}

                                    <p className="text-sm text-red-500">{errorMessage}</p>
                                </div>
                                <div className="flex gap-2 justify-end">
                                    <Button
                                        onClick={() => closeModal()}
                                        isDisabled={isCreating}
                                        className="btn btn-default"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        isLoading={isCreating}
                                        loadingText='Adding Account...'
                                        className="btn btn-primary">
                                        Add Account
                                    </Button>
                                </div>
                            </form>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateReceiverAccountModal;
