/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import axios from "axios";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import Card from "components/card";
import SweetAlert2 from "react-sweetalert2";
import { Select, Button } from '@chakra-ui/react';
import { IoCheckmark } from "react-icons/io5";
import InputField from "components/fields/InputField";

const PayoutMarkFailedConfirmModal: React.FC<{
    payout: any;
    isOpen: boolean;
    onClose(): void;
    triggerReload(): void;
}> = ({ isOpen, onClose, triggerReload, payout }) => {

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [failureType, setFailureType] = useState<string>("");
    const [reason, setReason] = useState<string>("");
    const [swalProps, setSwalProps] = useState<any>({});
    const userRole = localStorage.getItem("userRole");

    const isFormValid = failureType !== "" && reason !== "" ? true : false;

    const closeModal = async () => {
        setFailureType("");
        setReason("");
        onClose();
    };

    const displayAmount = (amount: any) => {
        if (amount === 0) {
            return "$0.00";
        }
        return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleSubmit = async () => {
        try {
            setIsProcessing(true);

            const data = {
                type: failureType,
                reason: reason,
                username: localStorage.getItem("username"),
                userRole: userRole,
                subaccount_id: payout.subaccount.id
            }

            await axios.put(process.env.REACT_APP_API_URL + `/api/payout/manual-failed/${payout.id}`, data)
                .then(function (response) {
                    triggerReload();
                    if (response && response.data?.status === 'ok') {
                        closeModal();
                        setSwalProps({
                            show: true,
                            icon: "success",
                            title: "Success",
                            html: "Payout has been updated",
                            showConfirmButton: true,
                            didClose: () => {
                                setSwalProps({});
                                setIsProcessing(false);
                            },
                        });
                    } else {
                        setIsProcessing(false);
                    }
                })
                .catch(function (error) {
                    setIsProcessing(false);
                    setSwalProps({
                        show: true,
                        icon: "error",
                        title: "Oops!",
                        html: error.response.data.message,
                        showConfirmButton: true,
                        didClose: () => {
                            setSwalProps({});
                            setIsProcessing(false);
                        },
                    });
                });
        } catch (error: any) {
            setIsProcessing(false);
            setSwalProps({
                show: true,
                icon: "error",
                title: "Oops!",
                html: "Something went wrong, please try again later.",
                showConfirmButton: true,
                didClose: () => {
                    setSwalProps({});
                    setIsProcessing(false);
                },
            });
        }
    };

    const fieldsRendering = (fields: Record<string, any>) => {
        const renderInput = (fieldName: string, fieldValue: any) => {
            const capitalizeLabel = (label: string) => {
                let modifiedLabel = label
                    .split('_')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');

                switch (label) {
                    case "bsb":
                        modifiedLabel = "BSB";
                        break;
                }

                return modifiedLabel;
            };

            return (
                <div className="mb-1" key={fieldName}>
                    <span className="mr-1 font-bold">{capitalizeLabel(fieldName)}:</span> {fieldValue}
                </div>
            );
        };

        return Object.entries(fields).map(([key, value]) => renderInput(key, value));
    };

    return (
        <>
            {payout &&
                <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                    <ModalOverlay className="bg-[#000] !opacity-30" />
                    <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                        <ModalBody className="!text-navy-700">
                            <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                                <h1 className="mb-[20px] text-2xl font-bold">Are you sure you want to mark failed the payout?</h1>
                                <div className="mb-[20px] text-sm">
                                    <div className="mb-8">
                                        Please confirm if you wish to mark as failed the current payout. This action cannot be undone.
                                    </div>
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Email:</span> {payout.receiver.email}
                                    </div>
                                    {fieldsRendering(payout.receiver_account.method_details)}
                                    <div className="mb-1 mt-3">
                                        <span className="mr-1 font-bold">Amount:</span> {displayAmount(payout.amount)} {payout.asset}
                                    </div>
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Note:</span> {!payout.note || payout.note === "" ? "-" : payout.note}
                                    </div>
                                    <div className="mb-1">
                                        <span className="mr-1 font-bold">Reference:</span> {!payout.reference || payout.reference === "" ? "-" : payout.reference}
                                    </div>

                                    <div className="flex justify-center"><hr className="mb-3 mt-3 w-full" /></div>

                                    <label className="mb-1 block text-sm font-bold text-navy-700 dark:text-white">
                                        Failure Type *
                                    </label>
                                    <Select disabled={isProcessing} id="gender" value={failureType} onChange={(e) => setFailureType(e.target.value)} className="h-10 rounded-md custom-select relative bg-white border border-gray-300 focus:outline-none py-2 pl-3 pr-10 sm:text-sm w-full mb-3 shadow-sm">
                                        <option value="">-- Please Select --</option>
                                        <option value="returned">Returned</option>
                                        <option value="rejected">Rejected</option>
                                    </Select>

                                    <label className="mb-1 block text-sm font-bold text-navy-700 dark:text-white">
                                        Reason *
                                    </label>
                                    <textarea
                                        disabled={isProcessing}
                                        className="mb-9 mt-2 text-md shadow-sm border text-primary rounded-md p-2 block w-full bg-white  border-gray-300 h-28 resize-none overflow-auto focus:outline-none"
                                        id="note"
                                        autoComplete="off"
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                    />
                                </div>
                                <div className="flex gap-2 justify-end">
                                    <Button
                                        onClick={() => closeModal()}
                                        isDisabled={isProcessing}
                                        className="btn btn-default"
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        onClick={() => handleSubmit()}
                                        isLoading={isProcessing}
                                        isDisabled={!isFormValid}
                                        loadingText='Processing...'
                                        className="btn btn-primary">
                                        <IoCheckmark className="h-4 w-4" />&nbsp;Confirm
                                    </Button>
                                </div>
                            </Card>
                        </ModalBody>
                    </ModalContent>
                </Modal>}

            <SweetAlert2 {...swalProps} customClass={{ actions: 'custom-swal2-popup-action', title: 'custom-swal2-popup-title', confirmButton: 'custom-swal2-popup-action custom-swal2-popup-action-confirm', cancelButton: 'custom-swal2-popup-action custom-swal2-popup-action-cancel' }}>

            </SweetAlert2>
        </>
    );
};

export default PayoutMarkFailedConfirmModal;
